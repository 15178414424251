<template>
	<div class="">
		<div class="mt-10">
			<p class="mt-10 size-px-16">① Google OTP 인증 앱을 스마트폰에 설치하세요.</p>
			<div class="mt-20">
				<a
					class="btn btn-primary radius-20"
					href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko&gl=US"
					target="store"
				><img :src="require('@/assets/image/ico_google.png')" alt="" class="width-20"></a>

				<a
					class="btn btn-primary mt-30 radius-20"
					href="https://apps.apple.com/kr/app/google-authenticator/id388497605"
					target="store"
				><img :src="require('@/assets/image/ico_app.png')" alt="" class="width-20"></a>
			</div>
		</div>

		<div class="mt-30">
			<p class="size-px-16">② Google Authenticator 앱에서 바코드를 스캔하세요.</p>
			<figure class="mt-30 text-center">
				<qr-code :text="text_qr_code" :size="160" style="display: inline-block;"></qr-code>
				<figcaption class="mt-10 text-left">바코드 스캔이 불가능한 경우 아래키를 입력하세요.</figcaption>
			</figure>
			<div class="mt-10 flex-row">
				<input
					v-model="gogle_otp_key"
					type="text" readonly
					class="input-box mr-10 radius-20"
				>
				<button class="btn-inline btn-primary radius-20" @click="copy">복사</button>
			</div>
		</div>

		<div class="mt-30">
			<p class="">③ Google OTP 인증번호</p>
			<div class="mt-10">
				<input
					v-model="gogle_otp_certfc_number"
					maxlength="6"
					type="text" placeholder="6자리 인증번호 입력"
					class="input-box mr-10 radius-20"
				>
			</div>
		</div>

		<div class="mt-30 text-center">
			<button
				class="btn-inline btn-primary radius-20"
				:disabled="gogle_otp_certfc_number.length != 6"

				@click="postOtp"
			>확인</button>
		</div>

	</div>
</template>

<script>


export default {
	name: 'mafia1114'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '구글 OTP'
				, title: 'Goggle OTP'
				, not_header: true
				, not_footer: true
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, gogle_otp_key: ''
			, gogle_otp_certfc_number: ''
			, nickname: ''
			, member_number: ''
		}
	}
	, computed: {
		text_qr_code: function(){
			let t = ''
			if(this.member_number && this.gogle_otp_key){
				t = 'otpauth://totp/METAMAFIA(' + this.member_number + ')?secret=' + this.gogle_otp_key
			}

			return t
		}
	}
	, methods: {
		postOtp: async function(){
			this.$log.console('postOtp')
			try{
				this.$bus.$emit('on', true)
				if(!this.gogle_otp_certfc_number){
					throw 'OTP 번호를 입력하세요'
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_change_otp
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: this.gogle_otp_certfc_number
					}
					, type: true
				})

				if(result.success){
					this.$emit('click')
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,pinClick: async function(){
			this.pin_option.pin_type = 'set'
			this.$emit('to', { name: 'mafia010'})
		}
		,pinCancel: function(){
			this.$log.console('cancel')
			this.is_pin = false
			this.pin_option.pin_type = 'set'
		}
		, getGoogleOtpKey: async function(){
			this.$log.console('getGoogleOtpKey')
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_google_otp_change_key
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: ''
					}
					, type: true
				})

				if(result.success){
					this.gogle_otp_key = result.data.gogle_otp_key
					this.nickname = result.data.nickname
					this.member_number = result.data.member_number
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			this.$log.console('t.value', t.value)
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){

			this.clipBoard(this.gogle_otp_key);
			this.$bus.$emit('notify',  { type: 'success', message: 'Google OTP 키가 복사되었습니다'})
		}

		, toGoogle: function(){

		}

	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getGoogleOtpKey()
	}
}
</script>